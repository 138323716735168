/* eslint-disable */

const AppHeader = () => import('./views/Header.vue' );
const AppFooter = () => import('./views/Footer.vue');

const IndexPage = () => import('./views/IndexPage.vue');
const UnderConstruction = () => import('./views/UnderConstruction.vue');
const AboutPage = () => import('./views/AboutPage.vue');
const MapPage = () => import('./views/MapPage.vue');
const HelloWorld = () => import('./components/HelloWorld.vue');
const FormPage = () => import('./views/FormPage.vue');

export const routes = [
    {
        name: 'UnderConstruction',
        path: '/',
        components: {
            header: AppHeader,
            default: AboutPage,
            //footer: AppFooter
        }
    },
    {
        name: 'IndexPage',
        path: '/start',
        components: {
            header: AppHeader,
            default: IndexPage,
            //footer: AppFooter
        }
    },
    {
        name: 'MapPage',
        path: '/maps',
        components: {
            //header: AppHeader,
            default: MapPage
        }
    },
    {
        name: 'HelloWorld',
        path: '/hello',
        components: {
            //header: AppHeader,
            default: HelloWorld,
            //footer: AppFooter
        }
    },
    {
        name: 'Form',
        path: '/form',
        components: {
            header: AppHeader,
            default: FormPage,
            //footer: AppFooter
        }
    },
    /*
    {name: 'index-es',path: '/es',components: {header:AppHeader,default:Main,footer:AppFooter}},
    {name: 'index-fr',path: '/fr',components: {header:AppHeader,default:Main,footer:AppFooter}},
    {name: 'index-ru',path: '/ru',components: {header:AppHeader,default:Main,footer:AppFooter}},
    {name: 'index-vi',path: '/vi',components: {header:AppHeader,default:Main,footer:AppFooter}},
    */
    {
        path: '/:catchAll(.*)',
        redirect: { name: 'UnderConstruction' }
    }
];
