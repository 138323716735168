import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import {createRouter, createWebHistory} from 'vue-router'  //createWebHashHistory
import { routes } from './routes'
const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history'
});

const appcar = createApp(App);
appcar.use(router);
appcar.use(vuetify);
appcar.directive('click-outside', {
    mounted(el, binding) { //vnode
        el.clickOutsideEvent = function(event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});
appcar.mount('#app');