// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
//import { aliases, mdi } from 'vuetify/iconsets/mdi'

export default createVuetify({
       icons: {
            defaultSet: 'mdi',
       },
    }
)
//// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
// https://next.vuetifyjs.com/en/features/icon-fonts/

